body,
html,
#root {
  height: 100%;
}

body {
  background: url(../src/images/imgpsh_fullsize_anim.png) right center no-repeat fixed;
  background-color: rgba(0, 0, 0, 1) !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.logo-section {
  display: block;
  padding: 15px 0 0px 0;
}

.form-box {
  background-color: #383838;
  border-radius: 13px;
  padding: 80px 60px;
}

.image-section {
  display: block;
}

.image-section img {
  max-width: 100%;
}

.form-box h3 {
  color: #fff;
  font-size: 70px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 18px;
}

.form-box p {
  color: #fff;
  font-size: 24px;
  text-align: center;
  margin-bottom: 35px;
}

.form-box .form-control {
  border-width: 0 0 1px 0;
  border-color: #fff;
  background-color: transparent;
  border-radius: 0;
  color: #fff;
  margin-bottom: 50px;
}

.form-box .form-control:focus {
  background-color: transparent;
  box-shadow: none;
}

.form-box .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1;
  /* Firefox */
}

.form-box .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

.form-box .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}

.form-box .btn-primary {
  background-color: #fff;
  border-color: #fff;
  color: #000000;
  width: 100%;
  position: relative;
  z-index: 10;
  transform-style: preserve-3d;
  padding-top: 27px;
  padding-bottom: 25px;
  font-size: 30px;
  font-weight: 700;
}

/* .form-box .btn-primary:after {
  content: "";
  border: 1px solid #fff;
  height: 100%;
  width: calc(100% - 30px);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -17px;
  transform: translateZ(-1px);
} */
.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.error-page h3 {
  color: #fff;
  font-size: 70px;
  font-weight: 700;
  margin-top: -20px;
  margin-bottom: 32px;
}

.error-page h3 b {
  display: block;
}

.error-page a {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
}

.video-main {
  display: flex;
  align-items: center;
  justify-content: center;
}


.logout {
  text-decoration: none;
  color: #fff;
  font-weight: bold;

}

.logout:hover {
  color: #fff;
}

.v-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.homeHeader {
  flex-direction: column;
  justify-content: center;
  text-align: center;
  /* padding-right: 5px; */
  text-align: center;
  padding: 10px;
  height: 50px;
  /* width: 100px; */
  /* float: right; */
}

iframe {
  margin: 0px;
  padding: 0px;
  border: 0px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  position: fixed;
  min-width: 0px;
  max-width: none;
  min-height: 0px;
  max-height: none;
  width: 100%;
  height: 94%;
  left: 0px;
  top: 40px;
}